import logo from './logo.svg';
import './App.css';
import Tab from './Tab';
import Photo from './Photo';
import SocialLinks from './SocialLinks';
import Name from './Name';

function App() {
  return (
    <div className="App">
      <Photo />
      <Name />
      <br/> 
      <Tab />
    </div>
  );
}

export default App;
