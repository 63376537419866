import React from 'react';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
 typography: {
   fontFamily: ['"Segoe UI"', 'Roboto'].join(','),
   body: {
       fontWeight: 1600
   }
  }
})

function Name() {
    return (
        <ThemeProvider theme={theme}>
        <div className="name">
            <Typography variant="h3">Aniket Kumar Sinha</Typography>
        </div>
        </ThemeProvider>
    )
}

export default Name
