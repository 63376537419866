import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Aniket from './aniket.jpeg';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

export default function Photo() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Grid 
        container 
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center">
            <Grid item xs={12}>
                <div className="circle">
                <Avatar alt="Remy Sharp" src={Aniket} className={classes.large} />
                </div>
            </Grid>
        </Grid>
    </div>
  );
}
