import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.dark,
    background: 'linear-gradient(45deg, #dbf6e9 30%, #dbf6e9 90%)',
  },
  typography: {
    fontFamily: '"Segoe UI"', 
  },
}));

export default function Tab() {
  const classes = useStyles();

  return (
      <div className="tabs">
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid className="tabName" item xs={12}>
          <a className="tabname" href="https://youtu.be/CqEm_ZbqbA0" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">Benefits of being a College Student | 🤑Free Stuffs worth Lakhs 🤑</Typography></Paper></a>
        </Grid> 
        <Grid className="tabName" item xs={12}>
          <a className="tabname" href="https://youtu.be/GM8R0X_ZLXU" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">All About Google Developer Student Club(GDSC) | What is GDSC?</Typography></Paper></a>
        </Grid> 
        <Grid className="tabName" item xs={12}>
          <a className="tabname" href="https://openinapp.co/youtube/52f3e" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">🚩 All About GCE(GitHub Campus Experts) | GCE Application 2021 🚩</Typography></Paper></a>
        </Grid> 
        <Grid className="tabName" item xs={12}>
          <a className="tabname" href="https://saurabhdaware.github.io/text-to-handwriting/" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">Text to Handwriting</Typography></Paper></a>
        </Grid> 
        <Grid className="tabName" item xs={12}>
          <a className="tabname" href="https://aniketkumarsinha.medium.com/how-to-get-microsoft-azure-fundamentals-certified-21a3830234d4" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">How to get Microsoft Azure Fundamentals Certified?</Typography></Paper></a>
        </Grid>
        {/* <Grid className="tabName" item xs={12}>
          <a className="tabname" href="https://amzn.to/2UbhAH1" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">Clean Code ~ Robert C. Martin</Typography></Paper></a>
        </Grid>
        <Grid className="tabName" item xs={12}>
          <a className="tabname" href="https://amzn.to/3iWUKNG" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">Code Complete ~ Steve Mcconnell</Typography></Paper></a>
        </Grid>
        <Grid className="tabName" item xs={12}>
          <a className="tabname" href="https://amzn.to/3cZkkOt" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">The Clean Coder ~ Robert C. Martin</Typography></Paper></a>
        </Grid> */}
        <Grid className="tabName" item xs={12}>
          <a className="tabname" href="https://medium.com/mscpilani/how-to-deploy-your-react-app-on-firebase-eea2ffc69705?source=friends_link&sk=c9e61fa2827445f8cf5972db23e5f164" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">How to deploy your React app on Firebase?</Typography></Paper></a>
        </Grid>
        <Grid className="tabName" item xs={12}>
          <a className="tabname" href="https://youtu.be/0b--zhGWBBU" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">How to Deploy React app on GitHub?</Typography></Paper></a>
        </Grid>
        <Grid className="tabName" item xs={12}>
          <a className="tabname" href="https://youtu.be/z1mHvjdal7M" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">Java through Notepad & Command Prompt</Typography></Paper></a>
        </Grid>
        <Grid className="tabName" item xs={12}>
          <a className="tabname" href="https://www.youtube.com/watch?v=uUpbXeqneos" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">"Hello World!" in ReactJs</Typography></Paper></a>
        </Grid>
        <Grid item xs={12}>
          <a className="tabname" href="https://www.youtube.com/watch?v=gCDwJpKjvWM  " target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">How to become Microsoft Learn Student Ambassador?</Typography></Paper></a>
        </Grid>
        <Grid item xs={12}>
          <a className="tabname" href="https://www.instagram.com/tv/CK56jBxAs47/?utm_source=ig_web_copy_link" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">MLSA Swag Kit Unboxing</Typography></Paper></a>
        </Grid>
        <Grid item xs={12}>
          <a className="tabname" href="https://www.tagmango.com/creator/aniket-kumar-sinha" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">LinkedIn Profile Building 1-on-1 Consultation</Typography></Paper></a>
        </Grid>
        <Grid item xs={12}>
          <a className="tabname" href="https://www.youtube.com/watch?v=1bxUvPxtKEc" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">GitHub and It's Functionalities (In 10 Minutes)</Typography></Paper></a>
        </Grid>
        <Grid item xs={12}>
          <a className="tabname" href="https://www.youtube.com/watch?v=xjcLRekEm8I" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">Leveraging Your LinkedIn</Typography></Paper></a>
        </Grid>
        <Grid item xs={12}>
          <a className="tabname" href="https://medium.com/@aniketkumarsinha" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">Blogs</Typography></Paper></a>
        </Grid>
        <Grid item xs={12}>
          <a className="tabname" href="https://www.instagram.com/aniketksinha/reels/" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">Reels</Typography></Paper></a>
        </Grid>
        <Grid item xs={12}>
          <a className="tabname" href="https://www.instagram.com/aniketksinha/" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">Instagram</Typography></Paper></a>
        </Grid>
        <Grid item xs={12}>
          <a className="tabname" href="https://www.linkedin.com/in/aniketkumarsinha/" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">LinkedIn</Typography></Paper></a>
        </Grid>
        <Grid item xs={12}>
          <a className="tabname" href="https://www.youtube.com/channel/UCEDK0r0TfURa1vYZ4sMW0jw" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">YouTube</Typography></Paper></a>
        </Grid>
        <Grid item xs={12}>
          <a className="tabname" href="https://github.com/aniket-sinha8" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">GitHub</Typography></Paper></a>
        </Grid>
        <Grid item xs={12}>
          <a className="tabname" href="https://twitter.com/AniketKSinha" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">Twitter</Typography></Paper></a>
        </Grid>
        <Grid item xs={12}>
          <a className="tabname" href="https://clubhouse.com/@aniketksinha" target="_blank"><Paper className={classes.paper}><Typography className="tabname" variant="h6">Clubhouse</Typography></Paper></a>
        </Grid>
      </Grid>
    </div>
    </div>
  );
}

